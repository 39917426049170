import axios from "axios";

export default {
  namespaced: true,
  state: {
    apiKey: "d367534bbc51ce89ff7b7d9c148476d5",
  },
  actions: {
    getCurrentWeather({ state }, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `https://api.openweathermap.org/data/2.5/weather?lat=${params.lat}&lon=${params.lng}&units=${params.unit}&appid=${state.apiKey}`
          )
          .then((res) => {

            if (res.status == 200) {

              resolve(res.data);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getWeeklyWeather({ state }, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `https://api.openweathermap.org/data/3.0/onecall?lat=${params.lat}&lon=${params.lng}&units=${params.unit}&exclude=minutely&appid=${state.apiKey}`
          )
          .then((res) => {
            if (res.status == 200) {
              resolve(res.data);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getCityWeather({ state }, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `https://api.openweathermap.org/data/2.5/weather?zip=${params.zip}&q=${params.city}&units=${params.unit}&appid=${state.apiKey}`
          )
          .then((res) => {
            if (res.status == 200) {
              resolve(res.data);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
